
import {
  ref,
  defineComponent,
  getCurrentInstance
} from 'vue'
import ListDataHjex from '@/views/shared-components/list/ListDataHjex.vue'
import {
  ITransDepositsRefunds
} from '@/utils/interfaces/iTransDepositsRefunds'
import rupiahFormat from '@/utils/helpers/rupiahFormat'
import {
  companyUseCase
} from '@/domain/usecase'
import {
  statusDepositRefund
} from '@/data/source/remote/api'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  components: {
    ListDataHjex
  },
  setup() {
    const app = getCurrentInstance()
    const { $toast } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const { permission }: any = route.meta
    const refListDataHjex = ref()
    const paramListData = ref({
      endpoint: '/company/v2/transdepositrefunds',
      title: 'Penarikan Deposit',
      fieldSearch: ['fullname']
    })

    const statusFinance = (status: string) => {
      let backgroundColor
      let color
      let title
      if (status.toLowerCase() === 'requested') {
        backgroundColor = '#F0F9FF'
        color = '#65C0ED'
        title = 'waiting'
      } else if (status.toLowerCase() === 'approved') {
        backgroundColor = '#FFF3E0'
        color = '#FF9800'
        title = 'requested'
      } else if (status.toLowerCase() === 'paid') {
        backgroundColor = '#E8F5E9'
        color = '#66BB6A'
        title = 'approved'
      }
      return {
        backgroundColor,
        color,
        title
      }
    }

    const statusAdmin = (status: string) => {
      let backgroundColor
      let color
      let title
      if (status.toLowerCase() === 'requested') {
        backgroundColor = '#FFF3E0'
        color = '#FF9800'
        title = 'requested'
      } else if (status.toLowerCase() === 'approved' || status.toLowerCase() === 'paid') {
        backgroundColor = '#E8F5E9'
        color = '#66BB6A'
        title = 'approved'
      }
      return {
        backgroundColor,
        color,
        title
      }
    }

    const colums = ref(['Nama Pelanggan', 'Email', 'Nominal Yang Ditarik', 'Admin Status', 'Keuangan Status', 'Actions'])

    const onChangeStatus = (value: ITransDepositsRefunds) => {
      store.dispatch('showLoading')
      const enumStatus = value.Status.toLowerCase() === 'requested' ? statusDepositRefund.approve : statusDepositRefund.pay
      companyUseCase.changeStatusTransDeposit(value.Id, enumStatus)
        .then(({ error, result }) => {
          if (!error) {
            refListDataHjex.value.getData()
            $toast.add({
              severity: 'success', detail: result.Detail, group: 'bc', life: 3000
            })
          }
          store.dispatch('hideLoading')
        })
        .catch((err) => {
          store.dispatch('hideLoading')
        })
    }
    return {
      statusFinance,
      statusAdmin,
      onChangeStatus,
      colums,
      refListDataHjex,
      paramListData,
      rupiahFormat,
      permission,
      store,
      PermissionEnum,
      checkPermission
    }
  },
})
